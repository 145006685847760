import React, { useState } from "react";
import { ReactComponent as GencoinIcon } from "../assets/icons/gencoin/generated.svg";
import { ReactComponent as GrowIcon } from "../assets/icons/grow.svg";
import { ReactComponent as ValueCreationIcon } from "../assets/icons/values/value-creation.svg";
import { ReactComponent as ImpactIcon } from "../assets/icons/impact.svg";
// import { ReactComponent as GLogo } from "../assets/gear-heart.svg";
import Modal from "../utils/Modal";

function Process() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  return (
    <section>
      <div
        className="max-w-6xl mx-auto px-4 sm:px-6 items-center"
        data-aos-id-gen-method-anchor
      >
        <div className=" md:py-20 border-t border-gray-800">
          <div className="">
            {/* Section header */}
            {/* <div className="max-w-3xl mx-auto text-center mb-12">
              <div
                className="h2 font-extrabold text-topaz-500  md:leading-normal mt-10"
                data-aos="fade-up"
              >
                How It Works
              </div>
            </div> */}
            {/* Mobile */}
            <div className="md:hidden flex flex-col items-center mt-24">
              <div
                className="flex flex-col items-center justify-center w-full mb-20"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <ValueCreationIcon className="w-36 mb-4 text-primary-1 fill-current" />

                <div className="h2 mb-2 text-white">Contribute</div>
                <p className="md:text-2xl text-xl text-white font-thin text-center mb-10 md:px-40 px-0">
                  Contribute in the community by purchasing gems for other
                  members' vaults as a new innovative way to practice generosity
                  and support their causes. Every act of generosity helps create
                  a ripple effect of positive change.
                </p>
                <img
                  className="w-full md:w-3/6 md:px-10 mb-10"
                  src={require(`../images/phone/contribute.png`).default}
                  alt="phone-orgs"
                />
              </div>
              <div
                className="flex flex-col items-center justify-center w-full mb-20"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <GencoinIcon className="w-36 mb-4 text-primary-1 fill-current" />
                <div className="h2 mb-2 text-white">Earn</div>
                <p className="md:text-2xl text-xl text-white font-thin text-center mb-10 md:px-40 px-0">
                  As you participate, you'll earn various digital assets and
                  tokens, including Gencoin which are generated when your own
                  vaults fill with gems.
                </p>
                <p className="md:text-2xl text-xl text-white font-thin text-center mb-10 md:px-40 px-0">
                  1 Gencoin has an in-platform value of 1 US Dollar, and you can
                  use them to support causes you care about or convert them to
                  cash. Our platform rewards you for your generosity and active
                  participation.
                </p>
                <img
                  className="w-full md:w-3/6 md:px-10 mb-10"
                  src={require(`../images/phone/earn.png`).default}
                  alt="phone-orgs"
                />
              </div>
              <div
                className="flex flex-col items-center justify-center w-full mb-20"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <GrowIcon className="w-36 mb-4 fill-current text-primary-1" />

                <div className="h2 mb-2 text-white">Multiply</div>
                <p className="md:text-2xl text-xl text-white font-thin text-center mb-10 md:px-40 px-0">
                  Generosity is a habit that grows with practice. With Generos,
                  you'll not only cultivate a mindset of abundance and a
                  generous heart, but also multiply your impact and resources
                  exponentially... your generosity knows no bounds.
                </p>
                <img
                  className="w-full md:w-3/6 md:px-10 mb-10"
                  src={require(`../images/phone/grow.png`).default}
                  alt="phone-orgs"
                />
              </div>
            </div>

            {/* Desktop and Tablet */}
            <div className="hidden md:flex flex-col">
              <div
                className="flex mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="flex flex-col items-start justify-center pr-6">
                  <div className="flex w-full items-center justify-center mb-10">
                    <ValueCreationIcon className="w-36 mb-4 text-primary-1 fill-current" />
                  </div>

                  <div className="h2 mb-2 text-white">Contribute</div>
                  <p className="md:text-2xl text-xl text-white font-thin mb-10">
                    Contribute in the community by purchasing gems for other
                    members' vaults as a new innovative way to practice
                    generosity and support their causes.
                  </p>
                  <p className="md:text-2xl text-xl text-white font-thin mb-10">
                    Every act of generosity helps create a ripple effect of
                    positive change.
                  </p>
                </div>

                <img
                  className="w-1/2"
                  src={require(`../images/phone/contribute.png`).default}
                  alt="phone-orgs"
                />
              </div>
              <div
                className="flex mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <img
                  className="w-1/2"
                  src={require(`../images/phone/earn.png`).default}
                  alt="phone-orgs"
                />

                <div className="flex flex-col items-start justify-center pl-6">
                  <div className="flex w-full items-center justify-center mb-10">
                    <GencoinIcon className="w-36 mb-4 text-primary-1 fill-current" />
                  </div>
                  <div className="h2 mb-2 text-white">Earn</div>
                  <p className="md:text-2xl text-xl text-white font-thin mb-10">
                    As you participate, you'll earn various digital assets and
                    tokens, including Gencoin which is generated when your own
                    vaults fill with gems.
                  </p>
                  <p className="md:text-2xl text-xl text-white font-thin mb-10">
                    1 Gencoin has an in-platform value of 1 US Dollar, and you
                    can use them to support causes you care about or convert
                    them to cash. Our platform rewards you for your generosity
                    and active participation.
                  </p>
                </div>
              </div>
              <div
                className="flex mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="flex flex-col items-start justify-center pr-6">
                  <div className="flex w-full items-center justify-center mb-10">
                    <GrowIcon className="w-36 mb-4 text-primary-1 fill-current" />
                  </div>

                  <div className="h2 mb-2 text-white">Multiply</div>
                  <p className="md:text-2xl text-xl text-white font-thin mb-10">
                    Generosity is a habit that grows with practice. With
                    Generos, you'll not only cultivate a mindset of abundance
                    and a generous heart, but also multiply your impact and
                    resources exponentially... your generosity knows no bounds.
                  </p>
                </div>

                <img
                  className="w-1/2"
                  src={require(`../images/phone/grow.png`).default}
                  alt="phone-orgs"
                />
              </div>
            </div>

            {/* Explainer video */}
            {/* <div className="mb-12">
              <div
                className="relative flex justify-center items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  className="mx-auto"
                  src={require("../images/scene-2-splash.png").default}
                  width="524"
                  height="104"
                  alt="Hero"
                />
                <a
                  className="absolute group"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsVideoStarted(true);
                    setVideoModalOpen(true);
                  }}
                  aria-controls="modal"
                >
                  <svg
                    className="w-16 h-16 sm:w-20 sm:h-20 hover:opacity-75 transition duration-150 ease-in-out"
                    viewBox="0 0 88 88"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <linearGradient
                        x1="78.169%"
                        y1="9.507%"
                        x2="24.434%"
                        y2="90.469%"
                        id="a"
                      >
                        <stop
                          stopColor="#EBF1F5"
                          stopOpacity=".8"
                          offset="0%"
                        />
                        <stop stopColor="#EBF1F5" offset="100%" />
                      </linearGradient>
                    </defs>
                    <circle fill="url(#a)" cx="44" cy="44" r="44" />
                    <path
                      className="fill-current text-purple-600"
                      d="M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z"
                    />
                  </svg>
                </a>
              </div>

              <div
                className="text-center text-primary-1 font-extrabold font-rubik italic text-2xl mt-6"
                data-aos-delay="2800"
              >
                An Algorithm Powered By Digital Generosity
              </div>

              <Modal
                id="modal"
                ariaLabel="modal-headline"
                show={videoModalOpen}
                handleClose={() => {
                  setIsVideoStarted(false);
                  setVideoModalOpen(false);
                }}
              >
                <div className="relative pb-9/16">
                  <iframe
                    className="absolute w-full h-full"
                    src={
                      isVideoStarted && "https://youtube.com/embed/pdvWZzXAKZE"
                    }
                    title="Video"
                    allowFullScreen
                  ></iframe>
                </div>
              </Modal>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
