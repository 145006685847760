import React, { useState } from "react";
import algo from "../assets/network.gif";
import Modal from "../utils/Modal";
import { ReactComponent as Sensei } from "../assets/sensei/vault.svg";

function Algo() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:py-20 border-t border-gray-800">
          {/* Items */}
          <div className="flex flex-col justify-center items-center">
            {/* <div
              className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-12 md:rtl flex flex-col justify-center items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <Sensei className="md:w-96 w-80 md:px-0 px-14" />
            </div> */}
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 flex flex-col justify-center items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-0 md:rtl flex flex-col justify-center items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {/* <ZenKarma className="md:w-96 w-80 md:px-0 px-14" /> */}
                <img className="mb-6" src={algo} alt="algorithm" />
              </div>

              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div
                  className="md:text-3xl md:leading-normal h4 mb-8 text-white md:text-left text-center"
                  data-aos="fade-up"
                >
                  Unlock an algorithm powered by digital generosity!
                </div>
                <div
                  className="md:text-2xl text-xl text-white font-thin md:text-left text-center mb-28"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  As member activity on our platform grows, so does the power of
                  our unique vault algorithm. Small acts of
                  <span className="font-rubik font-semibold italic text-primary-1">
                    {" digital generosity "}
                  </span>
                  create a ripple effect and benefits everyone in the community.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Algo;
