import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FormattedInput } from "@buttercup/react-formatted-input";
import { validateFields, phoneNumberPattern } from "../utils/helpers";

import Header from "../partials/Header";
import Footer from "../partials/Footer";
import PageIllustration from "../partials/PageIllustration";
import { ReactComponent as GLogo } from "../assets/gear-heart.svg";
import { ReactComponent as Sensei } from "../assets/sensei/gems.svg";
import ContentContainer from "../components/cards/content-container";
import CardActions from "../components/cards/card-actions";
import { useLoading } from "../hooks";
import { JOIN_WAITLIST_MUTATION } from "../graphql/mutations/waitlist";
import Input from "../components/Input";
import Button from "../components/Button";
import useSound from "use-sound";
import chime from "../assets/sounds/chime.mp3";

function JoinWaitlist() {
  const [inputState, setInputState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    why: "",
  });
  const { firstName, lastName, email, why } = inputState;
  const [joinWaitlist, { loading }] = useMutation(JOIN_WAITLIST_MUTATION);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const [playChime] = useSound(chime);

  useEffect(() => {
    if (!!showError) {
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  }, [showError]);

  const submitRequest = async () => {
    try {
      const { data } = await joinWaitlist({
        variables: { firstName, lastName, email, why },
      });

      if (data) {
        const { joinWaitlist: isSucessfull } = data;

        if (isSucessfull) {
          playChime();
          history.push("/join-waitlist-confirmation");
        } else {
          setShowError(true);
        }
      }
    } catch (error) {
      setShowError(true);
    }
  };

  const handleChange = ({ id, value }) =>
    setInputState({ ...inputState, [id]: value });

  const { isActionDisabled } = useLoading({ loading });

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <section>
          <div className="max-w-xl mx-auto px-4 sm:px-6 mt-24">
            <ContentContainer className="px-6 text-white text-xl text-center">
              <Sensei className="md:w-80 w-80 md:px-0 px-14 mb-6" />
              <div className="text-xl font-bold mb-6">Request An Invite</div>
              <div className="mb-6" data-aos="fade-up" data-aos-delay="800">
                Be Part of the Generosity Revolution!
              </div>

              {FIELDS.map(({ id, type, placeholder, minLength, maxLength }) => (
                <Input
                  key={id}
                  id={id}
                  type={type}
                  value={inputState[id]}
                  placeholder={placeholder}
                  minLength={minLength && minLength}
                  maxLength={maxLength && maxLength}
                  onChange={({ currentTarget: { id, value } }) =>
                    handleChange({ id, value })
                  }
                />
              ))}
              {showError && (
                <div className=" font-bold text-ruby-400 w-full mt-6 mb-3 p-3 rounded-lg">
                  <div className="text-xl">Unable to Complete</div>
                  <div className="text-sm">Please try again later</div>
                </div>
              )}
            </ContentContainer>
            <CardActions>
              <Button
                text="Submit"
                className="m-3"
                variant="primary-w"
                onClick={submitRequest}
                disabled={
                  isActionDisabled ||
                  !validateFields({
                    fields: VALIDATION_FIELDS,
                    state: inputState,
                  })
                }
              />
            </CardActions>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default JoinWaitlist;

const FIELDS = [
  {
    id: "firstName",
    type: "text",
    placeholder: "First Name",
    minLength: "27",
    maxLenth: "27",
  },
  {
    id: "lastName",
    type: "text",
    placeholder: "Last Name",
    minLength: "27",
    maxLenth: "27",
  },
  {
    id: "email",
    type: "text",
    placeholder: "Email",
  },
  {
    id: "why",
    type: "text",
    placeholder: "Why do you want to join Generos?",
    minLength: "27",
    maxLenth: "200",
  },
];

const VALIDATION_FIELDS = ["firstName", "lastName", "email", "why"];
